<template>
  <div>
    <dashboard-page-title :showSearch="false" :showFilter="false" :showMainActions="false" @addEvent="$router.push({name: 'addMerchant'})">
    إضافة تاجر
    </dashboard-page-title>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(onSubmit)">
        <b-row>
          <b-col md="3" class="mb-3">
            <input-form placeholder="أدخل الاسم" label="الاسم الثلاثى" v-model="merchant.full_name" name="merchant full_name" validate="required"></input-form>
          </b-col>
          <b-col md="3" class="mb-3">
            <input-form
              placeholder="أدخل البريد الالكترونى"
              label="البريد الالكترونى"
              v-model="merchant.email" name="merchant email"
              validate="required">
            </input-form>
          </b-col>
          <b-col md="3" class="mb-3">
            <input-form
              placeholder="رقم الهاتف"
              label="رقم الهاتف"
              v-model="merchant.phone" name="merchant phone"
              validate="required">
            </input-form>
          </b-col>
          <b-col md="3" class="mb-3">
            <input-form
              placeholder="رقم الهوية"
              label="رقم الهوية"
              v-model="merchant.national_id" name="merchant national_id"
              validate="required">
            </input-form>
          </b-col>
          <b-col md="3" class="mb-3">
            <input-form
              placeholder="اسم المتجر"
              label="اسم المتجر"
              v-model="merchant.store_name" name="merchant store_name"
              validate="required">
            </input-form>
          </b-col>
          <b-col md="3" class="mb-3">
            <input-form
              placeholder="عنوان المتجر"
              label="عنوان المتجر"
              v-model="merchant.store_address" name="merchant store_address"
              validate="required">
            </input-form>
          </b-col>
          <b-col md="3" class="mb-3">
            <main-select labelTitle="اختر المدينة" placeholder="اختر المدينة" label="name" :dir="'rtl'"  :options="cities" :reduce="city => city.id"  v-model="merchant.store_city" />
          </b-col>
          <b-col cols="12">
            <b-row>
              <b-col cols="3">
                <b-button variant="primary" type="submit" v-if="!loadingSubmit">اضافة تاجر</b-button>
                <b-button v-else variant="warning" class="text-white" disabled type="submit"> <spinner-loading text="loading"></spinner-loading> </b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </form>
    </ValidationObserver>
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
import merchants from '../services/transportation'
export default {
  components: { },
  mounted () {
    core.index()
  },
  data () {
    return {
      loadingSubmit: false,
      cities: null,
      merchant: {
        full_name: '',
        email: '',
        phone: '',
        store_name: '',
        store_address: '',
        store_city: ''
      }
    }
  },
  methods: {
    getCities () {
      merchants.getCities().then(res => {
        this.cities = res.data.data
        console.log(res.data)
      })
    },
    onSubmit () {
      this.loadingSubmit = true
      merchants.addMerchent(this.merchant).then(res => {
        console.log(res.data)
        this.loadingSubmit = false
      }).catch(() => {
        this.loadingSubmit = false
      })
    }
  },
  created () {
    this.getCities()
  }
}
</script>

<style>
  .vs__dropdown-toggle {
    background: #fff !important;
    border-radius: 10px !important;
  }
</style>
