import { transportation, marketPlace } from '@/axios'

export default {
  addCompany (payload) {
    return transportation().post('companies', payload)
  },
  addDriver (payload) {
    return transportation().post('drivers', payload)
  },
  requiredDocument (type) {
    return transportation().get(`required-docs?filters[type]=${type}`)
  },
  addRequiredDocument (payload) {
    return transportation().post('required-docs', payload)
  },
  getAllSettings (payload) {
    return transportation().get('settings', payload)
  },
  addDriverSetting (payload) {
    return transportation().patch('settings/drivers', payload)
  },
  addCompanySetting (payload) {
    return transportation().patch('settings/companies', payload)
  },
  addVehicleType (payload) {
    return transportation().post('allowed-vehicle-types', payload)
  },
  getVehicleType (id, payload) {
    return transportation().get(`allowed-vehicle-types/${id}`, payload)
  },
  editVehicleType (id, payload) {
    return transportation().patch(`allowed-vehicle-types/${id}`, payload)
  },
  addVehicleMake (payload) {
    return transportation().post('vehicle-makes', payload)
  },
  getVehicleMake (id, payload) {
    return transportation().get(`vehicle-makes/${id}`, payload)
  },
  editVehicleMake (id, payload) {
    return transportation().patch(`vehicle-makes/${id}`, payload)
  },
  editStatus (id) {
    return transportation().patch(`required-docs/status/${id}`)
  },
  changeDriverStatus (id, status) {
    return transportation().patch(`drivers/status/${id}`, { status: status })
  },
  changeCompanyStatus (id, status) {
    return transportation().patch(`companies/status/${id}`, { status: status })
  },
  getDriver (id) {
    return transportation().get(`drivers/${id}`)
  },
  editDriver (id, payload) {
    return transportation().patch(`drivers/${id}`, payload)
  },
  getCompany (id) {
    return transportation().get(`companies/${id}`)
  },
  editCompany (id, payload) {
    return transportation().patch(`companies/${id}`, payload)
  },
  getCities () {
    return marketPlace().get('cities')
  },
  driverStatus (id, status, payload) {
    const data = payload ? { ...payload, status } : { status }
    return transportation().patch(`drivers/status/${id}`, data)
  },
  companyStatus (id, status, payload) {
    const data = payload ? { ...payload, status } : { status }
    return transportation().patch(`companies/status/${id}`, data)
  }
}
